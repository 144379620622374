import "$styles/index.css";
import "$styles/syntax-highlighting.css";

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

document.querySelector(".theme-toggle").addEventListener("click", () => {
  if (document.documentElement.classList.contains("dark")) {
    localStorage.theme = "light";
  } else {
    localStorage.theme = "dark";
  }
  document.documentElement.classList.toggle("dark");
});
